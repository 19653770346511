import dynamic from 'next/dynamic';
import {FC} from 'react';
import {Section} from '@/components';
import * as FLAGS from '@/constants/flags';
import {SECTION_IDS} from '@/constants/sectionIDs';
import {useFlag} from '@/context';
import {LABEL_SECTION} from '@/data/label';
import {useCurrentDrug} from '@/hooks';
import {replaceVariablesInStr} from '@/utils/string';

const Msl = dynamic(() => import('../MSL'));
const ReportAdverseEvent = dynamic(() => import('../ReportAdverseEvent'));
const FDALabelInformationWithoutSSR = dynamic(
  () => import('./FDALabelInformation'),
);

interface LabelSectionProps {}

const LabelSection: FC<LabelSectionProps> = () => {
  const {drugName} = useCurrentDrug();
  const showMSL = useFlag(FLAGS.MEDICAL_SCIENCE_LIAISON_SECTION);
  const showRAE = useFlag(FLAGS.REPORT_ADVERSE_EVENT_SECTION);

  return (
    <Section
      title={replaceVariablesInStr(LABEL_SECTION.title, {
        brandName: drugName,
      })}
      id={SECTION_IDS.drugLabel}>
      <div className='min-h-[443px]'>
        <FDALabelInformationWithoutSSR />
      </div>

      <div className='flex h-fit w-full flex-col space-y-4 pb-4 sm:flex-row sm:space-x-4 sm:space-y-0 md:pb-0'>
        {showMSL ? (
          <div className='w-full md:w-1/2'>
            <Msl />
          </div>
        ) : null}
        {showRAE ? (
          <div className='w-full md:w-1/2'>
            <ReportAdverseEvent />
          </div>
        ) : null}
      </div>
    </Section>
  );
};

export default LabelSection;
