import {Button, Loader} from '@prescriberpoint/ui';
import dynamic from 'next/dynamic';
import {FC, useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import {CEVENT_PAYWALL_REGISTER, DlvPaywallRegister} from '@/constants';
import {PA_DOWNLOAD_COUNTER_COOKIE} from '@/constants/cookies';
import {useUser, useDlvDrugInfo, useHandleSetCookies} from '@/hooks';
import {customEvent, getEmailDomain} from '@/utils/gtm';
import {toSnakeCase} from '@/utils/string';

const RegistrationModal = dynamic(
  () => import('@/components/RegistrationModal'),
  {
    loading: () => <Loader />,
  },
);

interface DownloadButtonProps {
  fileUrl: string;
  fileName: string;
  primary?: boolean;
  className?: string;
}

const DownloadButton: FC<DownloadButtonProps> = ({
  fileUrl,
  fileName,
  primary = false,
  className,
}) => {
  const [_pdfUrl, setPdfUrl] = useState('');

  const {logged} = useUser();

  const [cookies] = useCookies(['paDownloadCounter']);
  const {handleSetCookie} = useHandleSetCookies();
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const dlvDrugData = useDlvDrugInfo();

  useEffect(() => {
    fetch(fileUrl).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        setPdfUrl(fileURL);
      });
    });
  }, [fileUrl]);

  const onDonwload = () => {
    if (!logged) {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 30); // Set expiration to 30 days from now

      handleSetCookie(
        PA_DOWNLOAD_COUNTER_COOKIE,
        cookies.paDownloadCounter ? parseInt(cookies.paDownloadCounter) + 1 : 1,
        {
          expires: expirationDate,
          path: '/',
        },
      );
    }

    window.open(fileUrl, '_blank');
  };

  useEffect(() => {
    if (!logged && parseInt(cookies.paDownloadCounter) >= 3) {
      setShowRegistrationModal(true);
      handleSetCookie(PA_DOWNLOAD_COUNTER_COOKIE, 0, {path: '/'});
    }
  }, [cookies.paDownloadCounter, logged, handleSetCookie]);

  const cEvent = (email: string) => {
    const data = {
      emailDomain: getEmailDomain(email),
      ...dlvDrugData,
    };
    customEvent<DlvPaywallRegister>(CEVENT_PAYWALL_REGISTER, data);
  };

  const buttonId = `download_${toSnakeCase(fileName)}`;

  return (
    <>
      {primary ? (
        <Button
          onClick={onDonwload}
          id={buttonId}
          iconRight='Download'
          stretched>
          Download
        </Button>
      ) : (
        <Button
          onClick={onDonwload}
          variant='text'
          id={buttonId}
          className={className}
          iconRight='Download'>
          Download
        </Button>
      )}
      {showRegistrationModal && (
        <RegistrationModal
          onClose={() => setShowRegistrationModal(false)}
          customEvent={cEvent}
        />
      )}
    </>
  );
};

export default DownloadButton;
